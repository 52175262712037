import React from 'react';
import { Spin, message } from 'antd';

import auth from '../auth';

class Callback extends React.Component {
  componentDidMount() {
    if (!window.location.hash) {
      return;
    }
    auth.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        window.location.hash = '';
        window.location.href = '';
        auth.localLogin(authResult);
      } else if (err) {
        console.log(err);
        message('Error logging in.');
      }
    });
  }

  render() {
    // const { email } = this.state;
    return (
      <div className="fullpage col ac jc">
        <Spin />
        <p>Logging you into Bagel...</p>
      </div>
    );
  }
}

export default Callback;
